export const truncateStr = (str: string, length: number) => {
    if (str.length > length) {
        return `${str.slice(0, length)}...`;
    }
    return str;
};

export const parseLocation = (location: string, relay: string, securityRelay: string) => {
    if (location.length === 0) return '--';

    let allRelay = '';

    const parsedRelay = relay.replace(/,/g, ', ');
    const parsedSecurityRelay = securityRelay.replace(/,/g, ', ');

    if (relay === '') {
        allRelay = parsedSecurityRelay;
    } else if (securityRelay === '') {
        allRelay = parsedRelay;
    } else {
        allRelay = `${parsedRelay}, ${parsedSecurityRelay}`;
    }

    if (allRelay === '') return location;

    const str = `${location}-${allRelay}`;

    return str;
};

export default null;
