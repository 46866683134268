
import { defineComponent } from 'vue';
import log from '@/views/pm/log/index.vue';

export default defineComponent({
    components: {
        log
    },
    setup() {
        return {};
    }
});
