
import { computed, defineComponent } from 'vue';
import { parseLocation, truncateStr } from './util';

export default defineComponent({
    props: {
        location: {
            type: String,
            required: true
        },
        relay: {
            type: String,
            required: true
        },
        securityRelay: {
            type: String,
            required: true
        }
    },
    setup(props) {
        const locationStr = computed(() => parseLocation(props.location, props.relay, props.securityRelay));
        const isToLong = computed(() => locationStr.value.length > 30);
        const limitedLocationStr = computed(() => truncateStr(locationStr.value, 30));

        return {
            locationStr,
            isToLong,
            limitedLocationStr
        };
    }
});
