
import {
    computed, defineComponent, PropType, ref, Ref, watch
} from 'vue';
import { propertyBaseRouter } from '@/router/data';
import router from '@/router/index';
import secondaryNav from '@/components/common/secondary-nav/index.vue';
import { limitErrorImg } from '@/methods/basicFun';
import {
    ListActionItem,
    ListHeaderItem,
    listPagination, RequestData
} from '@/components/common/list';
import {
    getMenus, getLogPageData, LogTypeEnum
} from '@/views/pm/log/util';
import { DoorLogSearchData } from '@/views/pm/log/search/all.type';
import EnlargeImage from '@/components/common/enlarge-image/index.vue';
import { pmLog, community, communityType } from '@/data';
import ExportLogDialog from '@/components/view/pm/export-log-dialog';
import propertyBreadCrumb from '@/components/view/pm/bread-crumb';
import { communityForPmApi } from '@/api';
import { logType, ExportTimeData } from './all.type';
import SearchModel from './search/index.vue';
import LogLocation from './location/log-location.vue';

const breadCrumb = [{ label: WordList.ProperAllTextLogs }];
export default defineComponent({
    components: {
        listPagination,
        secondaryNav,
        SearchModel,
        EnlargeImage,
        ExportLogDialog,
        LogLocation,
        propertyBreadCrumb
    },
    props: {
        type: {
            type: String as PropType<logType>,
            default: 'doorLogs'
        }
    },
    setup(props) {
        const imgItem = ref<Array<any>>([]);

        const enableRequest = ref(false);
        const communityInfoRequested = ref(false);

        const logTypes = ['doorLogs', 'callHistory', 'capture'];

        const setAllImg = (el: any) => {
            if (el) {
                limitErrorImg(el);
                imgItem.value.push(el);
            }
        };
        const secondMenus = ref(getMenus());
        // list 所需数据
        const headers: Ref<Array<ListHeaderItem>> = ref([]);
        const listRequestData: Ref<RequestData> = ref({
            url: '',
            param: {}
        });
        const action: Ref<Array<ListActionItem> | false> = ref(false);
        const showCheck = ref(true);
        const defaultAction: Ref<Array<string>> = ref([]);
        const deleteUrl = ref('');

        const updateToList = ref(0);
        const saveParamInPath = ref(false);
        const activeNavItem = ref(props.type);

        // 获取结束时间
        const isShowExportLog = ref(false);
        const exportTime: Ref<ExportTimeData> = ref({});
        const exportType = ref(1);
        const exportTitle = {
            doorLogs: WordList.ProperAllTextDoorLogs,
            callHistory: WordList.NavInHtmlMenuCall,
            temperatureLogs: WordList.ProperAllTextTemperatureLogs,
            capture: WordList.capture
        };
        const getExportTime = () => {
            if (props.type !== 'auditLogs' && props.type !== 'motionAlerts') {
                exportType.value = LogTypeEnum[props.type];
                pmLog.getExportTime({ Flag: LogTypeEnum[props.type] }, (res: ExportTimeData) => {
                    exportTime.value = res;
                });
            }
        };
        const closeExportLogDialog = () => {
            getExportTime();
            isShowExportLog.value = false;
        };

        const logSaveDaysTips = computed(() => {
            let logTip = WordList.ProperAllTextLogSaveDays;

            switch (props.type) {
                case 'doorLogs':
                    logTip = WordList.DoorLogSaveDays;
                    break;
                case 'callHistory':
                    logTip = WordList.CallHistorySaveDays;
                    break;
                case 'capture':
                    logTip = WordList.CaptureLogSaveDays;
                    break;
                case 'temperatureLogs':
                    logTip = WordList.TemperatureLogSaveDays;
                    break;
                case 'motionAlerts':
                    logTip = WordList.MotionLogSaveDays;
                    break;
                default:
                    break;
            }

            return logTip;
        });

        watch([activeNavItem, community.ProjectType, community.enableShowLog, community.communityInfoRequested], () => {
            saveParamInPath.value = false;
            const pageData = getLogPageData(community.ProjectType.value)[activeNavItem.value];
            headers.value = pageData.header;
            listRequestData.value = pageData.requestData;
            showCheck.value = pageData.showCheck ? pageData.showCheck : false;
            defaultAction.value = pageData.defaultAction ? pageData.defaultAction : [];
            deleteUrl.value = pageData.deleteUrl ? pageData.deleteUrl : '';
            action.value = pageData.action;
            getExportTime();

            // 拥有log访问权限或者访问的不是log，才发送请求
            if (community.communityInfoRequested.value && (community.enableShowLog.value || !logTypes.includes(activeNavItem.value))) {
                enableRequest.value = true;
            }
        }, {
            immediate: true
        });

        watch([community.isNew, communityInfoRequested], () => {
            if (community.ProjectType.value === 'community' && community.isNew.value) {
                secondMenus.value.push({
                    label: WordList.ProperAllTextOperationLogs,
                    key: 'auditLogs',
                    path: 'auditLogs'
                });
            }
        }, {
            immediate: true
        });

        communityForPmApi.getPMCommunityDetail((res: communityType.CommunityDetail) => {
            communityInfoRequested.value = true;
            community.communityInfoRequested.value = true;
            community.enableShowLog.value = res.EnableShowLog === 1;
            if (!community.enableShowLog.value && logTypes.includes(props.type)) {
                activeNavItem.value = 'temperatureLogs';
                router.push({
                    query: {
                        type: activeNavItem.value
                    }
                });
            } else {
                activeNavItem.value = props.type;
            }
            secondMenus.value = getMenus();
        });

        const changePath = (key: string, type: string) => {
            router.push({
                query: {
                    type
                }
            });
        };

        // 搜索按钮事件
        const search = (searchData: DoorLogSearchData) => {
            listRequestData.value.param = { ...searchData };
            updateToList.value += 1;
            saveParamInPath.value = true;
        };

        // 图片放大的组件
        const isShowEnlargeImage = ref(false);
        const picAddress = ref('');
        const showEnlargeImage = (address: string) => {
            picAddress.value = address;
            isShowEnlargeImage.value = true;
        };

        // 控制温度显示类型
        const unit = ref('0');
        const unitChange = (unitReturn: string) => {
            unit.value = unitReturn;
        };

        return {
            headers,
            listRequestData,
            updateToList,
            changePath,
            secondMenus,
            activeNavItem,
            action,
            search,
            isShowEnlargeImage,
            picAddress,
            showEnlargeImage,
            getExportTime,
            showCheck,
            exportTime,
            exportType,
            exportTitle,
            isShowExportLog,
            closeExportLogDialog,
            unitChange,
            unit,
            defaultAction,
            deleteUrl,
            breadCrumb,
            saveParamInPath,
            setAllImg,
            community,
            logSaveDaysTips,
            enableRequest
        };
    }
});
